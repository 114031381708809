.InputResult {
    margin: 30px 0 0 20px;
    position: relative;
}

.TextArea {
    resize: none;
    width: 290px;
    text-align: right;
    color: #9c9999;
    padding: 4px 11px 0 11px;
}

@media (max-width: 800px) {
    .InputResult {
        padding-bottom: 30px;
    }
}