.CalcBody {
    width: 320px;
    height: 400px;
    background: linear-gradient(180deg, #6699FF, #0000FF);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
    padding: 15px;
    border-radius: 10px;
    margin-top: 30px;
    position: relative;
}

input {
    width: 100%;
    height: 60px;
    border-radius: 5px;
    outline: none;
    text-align: right;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 24px;
    color: rgb(94, 101, 110);
}

.CalcKey {
    background-color: rgba(204, 175, 14, 0.486);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: Verdana,Geneva,sans-serif;
    font-weight: 500;
    border-radius: 2px;
    color: wheat;
    border-right: solid 2px #50565e;
    border-bottom: solid 2px #50565e;
}



form {
    width: 90%;
}