.ButtonOff {
    /*display: block;*/
    position: absolute;
    top: 125px;
    left: 645px;
    cursor: pointer;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 15px;
    border: 1px solid #c5c2c2;
    background-color: #fff;
}

.ButtonOff:hover {
    border-color: #e08b8b;
    box-shadow: 0 0 15px -8px red;
}

.ButtonOff:hover img {
    filter:
            invert(0.4)
            sepia(1)
            hue-rotate(310deg)
            saturate(200%)
            brightness(1)
            contrast(175%)
    ;
}

@media (max-width: 800px) {
    .ButtonOff {
        top: 550px;
        left: 330px;
    }
}